import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import style from "./footer.module.scss";
import { axios, api } from "../../services";
import logoNew from "../../uploads/images/logo.png";
import upstock from "../../uploads/Upstox-logo.webp";

function Index() {
  const courseListInitial = {
    isLoading: true,
    error: false,
    courseList: [],
  };

  const [courseLists, setCourseLists] = useState(courseListInitial);

  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchCourse = async (req, res) => {
    setCourseLists({ ...courseLists, isLoading: false });
    try {
      const result = await axios.get(api.HOME);
      if (result.data?.category) {
        result.data?.category.map((c) => {
          if (!c.is_master) {
            setCourseLists({
              ...courseLists,
              courseList: c.category,
              isLoading: false,
            });
          }
        });
      }
    } catch (error) {
      setCourseLists({ ...courseLists, error: true, isLoading: false });
    }
  };

  return (
    <div className={style.footer}>
      <div className={style.container}>
        <div className={style.footer_links}>
          <img
            src={logoNew}
            loading="lazy"
            alt=""
            style={{ maxWidth: "100%" }}
          />
          <br />
          <hr />

          <div style={{ textAlign: "center" }}>
            <div className="d-flex align-items-center justify-content-evenly">
              
            </div>
            {/* <a
              href="https://upstox.com/open-account/?f=EV"
              target="_blank"
              style={{
                background: "white",
                padding: 8,
                borderRadius: 8,
                textDecoration: "none",
              }}
            >
              Click Here&nbsp;&nbsp;
              <i className="bi bi-arrow-right-circle" />
            </a> */}
          </div>
        </div>
        <div className={style.footer_links}>
          <p
            style={{
              fontWeight: 700,
              fontSize: "125%",
              color: "#FFC107",
              marginBottom: 15,
            }}
          >
            Important Links
          </p>
          <ul style={{ listStyle: "none" }}>
            <li>
              <Link to="/about-us" className={style.footer_link_item}>
                <i className="bi bi-arrow-right-circle" />
                &nbsp;&nbsp;About Us
              </Link>
            </li>
            <li>
              <Link to="/faq/general" className={style.footer_link_item}>
                <i className="bi bi-arrow-right-circle" />
                &nbsp;&nbsp;FAQs
              </Link>
            </li>

            <li>
              <Link to="/contact" className={style.footer_link_item}>
                <i className="bi bi-arrow-right-circle" />
                &nbsp;&nbsp;Contact
              </Link>
            </li>
            <li>
              <Link to="/refund-policy" className={style.footer_link_item}>
                <i className="bi bi-arrow-right-circle" />
                &nbsp;&nbsp;Refund Policy
              </Link>
            </li>

            <li>
              <Link
                to="/terms-and-conditions"
                className={style.footer_link_item}
              >
                <i className="bi bi-arrow-right-circle" />
                &nbsp;&nbsp;Terms & Conditions
              </Link>
            </li>
            <li>
              <Link to="/privacy-policy" className={style.footer_link_item}>
                <i className="bi bi-arrow-right-circle" />
                &nbsp;&nbsp;Privacy Policy &amp; Legal Disclaimer
              </Link>
            </li>
            <li>
              <Link to="/grievance-redressal-policy" className={style.footer_link_item}>
                <i className="bi bi-arrow-right-circle" />
                &nbsp;&nbsp;Grievance Redressal Policy
              </Link>
            </li>
          </ul>
        </div>
        <div className={style.footer_links}>
          <p
            style={{
              fontWeight: 700,
              fontSize: "125%",
              color: "#FFC107",
              marginBottom: 15,
            }}
          >
            Registration Details
          </p>
          <ul style={{ listStyle: "none" }}>
            <li>
              <i className="bi bi-arrow-right-circle" />
              &nbsp;&nbsp;Name: RAJENDRA VITTHAL SURYAWANSHI
            </li>
            <li>
              <i className="bi bi-arrow-right-circle" />
              &nbsp;&nbsp;Registration number: INH000007094
            </li>
            <li>
              <i className="bi bi-arrow-right-circle" />
              &nbsp;&nbsp;Registered address: F1-13 Nice Sankul Near ITI Signal, Trimbak Road Satpur Nashik, NASHIK, MAHARASHTRA, 422007
            </li>
            <li>
              <i className="bi bi-arrow-right-circle" />
              &nbsp;&nbsp;SEBI regional office address: Address: PN-C/7, G Block BKC, Bandra Kurla Complex, Bandra East, Mumbai, Maharashtra 400051
            </li>
            <li>
              <i className="bi bi-arrow-right-circle" />
              &nbsp;&nbsp;Contact details : +91 8767265434,+91 8010292914 Monday to Saturday from 10 am to 6 pm
            </li>
          </ul>
        </div>
      </div>
      
      <div className={style.copy_right}>
        <div className={style.container}>
          <div
            style={{ float: "left", lineHeight: 2.6 }}
            className={style.neutrolink_container}
          >
            <span style={{ float: "left" }}>
              ©2023 Rajendra Suryawanshi Stock Market Academy
            </span>
          </div>
          <div
            style={{ float: "right" }}
            className={style.neutrolink_container}
          >
            {/*<span>Developer By &nbsp;</span>
            <a
              className={style.neutrolink}
              href="https://neutrolink.in"
              title="Neutrolink"
            >
              <img
                loading="lazy"
                src="https://neutrolink.in/static/images/neutrolink_assets/favicon.jpeg"
                alt="Neutrolink"
                width="35px"
                height="35px"
              />
            </a>*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
